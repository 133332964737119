import Api from '../Api';
import history from '../history';
import {
  ARRAY, NUMBER, COUNT, SKIPPED, LIMIT,
} from '../constants';
import {
  GET_CUSTOM_COMPANY_PRODUCTS, GET_CUSTOM_COMPANY_PRODUCTS_COUNT,
  GET_CUSTOM_COMPANY_PRODUCT_FOR_EDIT, GET_CUSTOM_COMPANY_PRODUCTS_SKIP,
  GET_CUSTOM_COMPANY_PRODUCTS_LIMIT,
} from './types';
import {
  sendErrorMessage, validateResponseData, sendSuccessMessage,
  generateError, getCompanyIdFromState, getCompanyIdFromStateSafely,
} from '../helper';

export const clientUrl = '/dashboard/customCompanyProduct';
const apiUrl = '/customCompanyProduct';
const addSuccessMsg = 'Product added successfuly!';
const updateSuccessMsg = 'Product updated successfully!';
const noDocErr = 'No product found!';

export const getAll = (values = {}) => async (dispatch, getState) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;

    const cid = getCompanyIdFromStateSafely(getState);
    if (cid) params._company_eq = cid;

    const { data } = await Api.get(url, { params });

    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCTS, payload });

    const countPayload = validateResponseData({
      data, elementName: COUNT, elementType: NUMBER,
    });
    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCTS_COUNT, payload: countPayload });

    const skipPayload = validateResponseData({
      data, elementName: SKIPPED, elementType: NUMBER,
    });
    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCTS_SKIP, payload: skipPayload });

    const limitPayload = validateResponseData({
      data, elementName: LIMIT, elementType: NUMBER,
    });
    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCTS_LIMIT, payload: limitPayload });

    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCT_FOR_EDIT });
  } catch (err) { sendErrorMessage(err); }
};

export const addNew = (values) => async () => {
  try {
    const { data } = await Api.post(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(addSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};

export const getOne = (values) => async (dispatch, getState) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;
    params._company_eq = getCompanyIdFromState(getState);
    if (!params || !params._id) throw generateError('No id found!');

    const { data } = await Api.get(url, { params });

    const docArr = validateResponseData({ data, elementType: ARRAY });
    if (docArr.length === 0) throw generateError(noDocErr);

    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCT_FOR_EDIT, payload: docArr[0] });
  } catch (err) {
    sendErrorMessage(err);
    history.push(clientUrl);
  }
};

export const updateOne = (values) => async () => {
  try {
    const { data } = await Api.put(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(updateSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};
