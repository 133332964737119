import Api from '../Api';
import history from '../history';
import {
  ARRAY, NUMBER, COUNT, SKIPPED, LIMIT,
} from '../constants';
import {
  GET_ORDER_FOR_EDIT, GET_ORDERS, GET_ORDERS_COUNT, GET_ORDERS_SKIP, GET_ORDERS_LIMIT,
} from './types';
import { sendErrorMessage, validateResponseData, sendSuccessMessage } from '../helper';

export const clientUrl = '/dashboard/order';
const apiUrl = '/order';
const addSuccessMsg = 'Order placed successfuly!';
const updateSuccessMsg = 'Order processed successfully!';

export const getAll = (values = {}) => async (dispatch) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;

    const { data } = await Api.get(url, { params });

    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_ORDERS, payload });

    const countPayload = validateResponseData({
      data, elementName: COUNT, elementType: NUMBER,
    });
    dispatch({ type: GET_ORDERS_COUNT, payload: countPayload });

    const skipPayload = validateResponseData({
      data, elementName: SKIPPED, elementType: NUMBER,
    });
    dispatch({ type: GET_ORDERS_SKIP, payload: skipPayload });

    const limitPayload = validateResponseData({
      data, elementName: LIMIT, elementType: NUMBER,
    });
    dispatch({ type: GET_ORDERS_LIMIT, payload: limitPayload });

    dispatch({ type: GET_ORDER_FOR_EDIT });
  } catch (err) { sendErrorMessage(err); }
};

export const addNew = (values) => async () => {
  try {
    const { data } = await Api.post(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(addSuccessMsg);

    history.push('/dashboard/companyOrder');
  } catch (err) { sendErrorMessage(err); }
};

export const getOne = (id) => async (dispatch) => {
  try {
    const { data } = await Api.get(`${apiUrl}/orderDetail/${id}`);
    const payload = validateResponseData({ data });
    dispatch({ type: GET_ORDER_FOR_EDIT, payload });
  } catch (err) {
    sendErrorMessage(err);
    history.push(clientUrl);
  }
};

export const updateOne = (values) => async () => {
  try {
    const { data } = await Api.put(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(updateSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};
