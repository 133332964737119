import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

let composeEnhancers = compose;
const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: reduxDevTools } = window;
if (process.env.NODE_ENV === 'development' && reduxDevTools) composeEnhancers = reduxDevTools;

const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(thunk)));

export default store;
