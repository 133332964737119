import {
  GET_COMPANY_PRODUCTS, GET_COMPANY_PRODUCTS_COUNT, GET_COMPANY_PRODUCT_FOR_EDIT,
  GET_COMPANY_PRODUCTS_SKIP, GET_COMPANY_PRODUCTS_LIMIT, GET_COMPANY_PRODUCTS_FOR_IMPORT,
} from '../actions/types';

const initialValues = { _product: '', sku: '', pendingStock: '' };
const defaultState = {
  docs: [], count: 0, skipped: 0, limit: 0, initialValues, importDocs: [],
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_COMPANY_PRODUCTS:
      return { ...state, docs: payload || [] };
    case GET_COMPANY_PRODUCTS_COUNT:
      return { ...state, count: payload || 0 };
    case GET_COMPANY_PRODUCTS_SKIP:
      return { ...state, skipped: payload || 0 };
    case GET_COMPANY_PRODUCTS_LIMIT:
      return { ...state, limit: payload || 0 };
    case GET_COMPANY_PRODUCT_FOR_EDIT:
      return { ...state, initialValues: payload || initialValues };
    case GET_COMPANY_PRODUCTS_FOR_IMPORT:
      return { ...state, importDocs: payload || [] };
    default:
      return state;
  }
};
