import Api from '../Api';
import {
  ARRAY, NUMBER, COUNT, SKIPPED, LIMIT,
} from '../constants';
import {
  GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES, GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES_COUNT,
  GET_CUSTOM_COMPANY_PRODUCT_INVENTORY_FOR_EDIT, GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES_SKIP,
  GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES_LIMIT, GET_CUSTOM_COMPANY_PRODUCT_FOR_EDIT,
} from './types';
import {
  sendErrorMessage, validateResponseData, sendSuccessMessage,
  generateError, getCompanyIdFromState, getDataFromState,
} from '../helper';

export const clientUrl = '/dashboard/customCompanyProductInventory';
const apiUrl = '/customCompanyProductInventory';
const addSuccessMsg = 'Inventory added successfuly!';
const updateSuccessMsg = 'Inventory updated successfully!';

export const getAll = (values = {}) => async (dispatch, getState) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;
    params._company_eq = getCompanyIdFromState(getState);

    const { data } = await Api.get(url, { params });

    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES, payload });

    const countPayload = validateResponseData({
      data, elementName: COUNT, elementType: NUMBER,
    });
    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES_COUNT, payload: countPayload });

    const skipPayload = validateResponseData({
      data, elementName: SKIPPED, elementType: NUMBER,
    });
    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES_SKIP, payload: skipPayload });

    const limitPayload = validateResponseData({
      data, elementName: LIMIT, elementType: NUMBER,
    });
    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES_LIMIT, payload: limitPayload });

    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCT_INVENTORY_FOR_EDIT });
  } catch (err) { sendErrorMessage(err); }
};

export const addNew = (values, setEmptyPids, cb) => async (dispatch, getState) => {
  try {
    const { data } = await Api.post(apiUrl, values);

    const docs = getDataFromState(getState, 'customCompanyProductInventory', 'docs');
    if (!docs || !Array.isArray(docs)) throw generateError();

    const newDocs = validateResponseData({ data, elementType: ARRAY });
    const payload = [...docs, ...newDocs];
    const pids = newDocs.map((x) => x._id);
    setEmptyPids([...pids, ...values.existingPids]);

    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES, payload });
    cb();
    sendSuccessMessage(addSuccessMsg);
  } catch (err) { sendErrorMessage(err); }
};

export const updateStock = (values, cb) => async (dispatch, getState) => {
  try {
    const { data } = await Api.patch(`${apiUrl}/updateStock`, values);

    const error = generateError();

    const doc = validateResponseData({ data });
    if (!doc || typeof doc !== 'object' || !doc._id) throw error;

    const payload = getDataFromState(getState, 'customCompanyProductInventory', 'docs');
    if (!payload || !Array.isArray(payload)) throw error;

    const index = payload.findIndex((x) => x._id === doc._id);
    if (index < 0) throw error;

    payload[index].stock = doc.stock;

    const product = getDataFromState(getState, 'customCompanyProduct', 'initialValues');
    if (!product || typeof product !== 'object' || !product._id) throw error;

    const multiplyWith = values.type === 'add' ? -1 : 1;
    product.pendingStock += values.stock * multiplyWith;

    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCT_FOR_EDIT, payload: product });
    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES, payload });
    cb();
    sendSuccessMessage(updateSuccessMsg);
  } catch (err) { sendErrorMessage(err); }
};

export const getAllByLocation = (_location) => async (dispatch) => {
  try {
    const { data } = await Api.get(`${apiUrl}/location/${_location}`);
    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES, payload });
  } catch (err) { sendErrorMessage(err); }
};
