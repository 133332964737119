// auth constants
export const AUTH_USER = 'AUTH_USER';


// Company constants
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_COUNT = 'GET_COMPANIES_COUNT';
export const GET_COMPANIES_SKIP = 'GET_COMPANIES_SKIP';
export const GET_COMPANIES_LIMIT = 'GET_COMPANIES_LIMIT';
export const GET_COMPANY_FOR_EDIT = 'GET_COMPANY_FOR_EDIT';


// Features constants
export const GET_FEATURES = 'GET_FEATURES';
export const GET_FEATURES_COUNT = 'GET_FEATURES_COUNT';
export const GET_FEATURES_SKIP = 'GET_FEATURES_SKIP';
export const GET_FEATURES_LIMIT = 'GET_FEATURES_LIMIT';
export const GET_FEATURE_FOR_EDIT = 'GET_FEATURE_FOR_EDIT';


// Features Type constants
export const GET_FEATURE_TYPES = 'GET_FEATURE_TYPES';
export const GET_FEATURE_TYPES_COUNT = 'GET_FEATURE_TYPES_COUNT';
export const GET_FEATURE_TYPES_SKIP = 'GET_FEATURE_TYPES_SKIP';
export const GET_FEATURE_TYPES_LIMIT = 'GET_FEATURE_TYPES_LIMIT';
export const GET_FEATURE_TYPE_FOR_EDIT = 'GET_FEATURE_TYPE_FOR_EDIT';


// LOCATION constants
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_COUNT = 'GET_LOCATIONS_COUNT';
export const GET_LOCATIONS_SKIP = 'GET_LOCATIONS_SKIP';
export const GET_LOCATIONS_LIMIT = 'GET_LOCATIONS_LIMIT';
export const GET_LOCATION_FOR_EDIT = 'GET_LOCATION_FOR_EDIT';
export const GET_STOCK_MOVING_LOCATION = 'GET_STOCK_MOVING_LOCATION';


// Product constants
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_COUNT = 'GET_PRODUCTS_COUNT';
export const GET_PRODUCTS_SKIP = 'GET_PRODUCTS_SKIP';
export const GET_PRODUCTS_LIMIT = 'GET_PRODUCTS_LIMIT';
export const GET_PRODUCT_FOR_EDIT = 'GET_PRODUCT_FOR_EDIT';
export const GET_NEW_SHIPMENT_PRODUCTS = 'GET_NEW_SHIPMENT_PRODUCTS';
export const GET_STOCK_MOVING_PRODUCT = 'GET_STOCK_MOVING_PRODUCT';
export const GET_MY_COMPANY_PRODUCTS = 'GET_MY_COMPANY_PRODUCTS';


// Inventory constants
export const GET_INVENTORIES = 'GET_INVENTORIES';
export const GET_INVENTORIES_COUNT = 'GET_INVENTORIES_COUNT';
export const GET_INVENTORIES_SKIP = 'GET_INVENTORIES_SKIP';
export const GET_INVENTORIES_LIMIT = 'GET_INVENTORIES_LIMIT';
export const GET_INVENTORY_FOR_EDIT = 'GET_INVENTORY_FOR_EDIT';


// User constants
export const GET_USERS = 'GET_USERS';
export const GET_USERS_COUNT = 'GET_USERS_COUNT';
export const GET_USERS_SKIP = 'GET_USERS_SKIP';
export const GET_USERS_LIMIT = 'GET_USERS_LIMIT';
export const GET_USER_FOR_EDIT = 'GET_USER_FOR_EDIT';


// Company LOCATION constants
export const GET_COMPANY_LOCATIONS = 'GET_COMPANY_LOCATIONS';
export const GET_COMPANY_LOCATIONS_COUNT = 'GET_COMPANY_LOCATIONS_COUNT';
export const GET_COMPANY_LOCATIONS_SKIP = 'GET_COMPANY_LOCATIONS_SKIP';
export const GET_COMPANY_LOCATIONS_LIMIT = 'GET_COMPANY_LOCATIONS_LIMIT';
export const GET_COMPANY_LOCATION_FOR_EDIT = 'GET_COMPANY_LOCATION_FOR_EDIT';


// Company Inventory constants
export const GET_COMPANY_INVENTORIES = 'GET_COMPANY_INVENTORIES';
export const GET_COMPANY_INVENTORIES_COUNT = 'GET_COMPANY_INVENTORIES_COUNT';
export const GET_COMPANY_INVENTORIES_SKIP = 'GET_COMPANY_INVENTORIES_SKIP';
export const GET_COMPANY_INVENTORIES_LIMIT = 'GET_COMPANY_INVENTORIES_LIMIT';
export const GET_COMPANY_INVENTORY_FOR_EDIT = 'GET_COMPANY_INVENTORY_FOR_EDIT';


// Company Product constants
export const GET_COMPANY_PRODUCTS = 'GET_COMPANY_PRODUCTS';
export const GET_COMPANY_PRODUCTS_COUNT = 'GET_COMPANY_PRODUCTS_COUNT';
export const GET_COMPANY_PRODUCTS_SKIP = 'GET_COMPANY_PRODUCTS_SKIP';
export const GET_COMPANY_PRODUCTS_LIMIT = 'GET_COMPANY_PRODUCTS_LIMIT';
export const GET_COMPANY_PRODUCT_FOR_EDIT = 'GET_COMPANY_PRODUCT_FOR_EDIT';
export const GET_COMPANY_PRODUCTS_FOR_IMPORT = 'GET_COMPANY_PRODUCTS_FOR_IMPORT';


// Features Type constants
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_COUNT = 'GET_ORDERS_COUNT';
export const GET_ORDERS_SKIP = 'GET_ORDERS_SKIP';
export const GET_ORDERS_LIMIT = 'GET_ORDERS_LIMIT';
export const GET_ORDER_FOR_EDIT = 'GET_ORDER_FOR_EDIT';


// Container Order constants
export const GET_CONTAINER_ORDERS = 'GET_CONTAINER_ORDERS';
export const GET_CONTAINER_ORDERS_COUNT = 'GET_CONTAINER_ORDERS_COUNT';
export const GET_CONTAINER_ORDERS_SKIP = 'GET_CONTAINER_ORDERS_SKIP';
export const GET_CONTAINER_ORDERS_LIMIT = 'GET_CONTAINER_ORDERS_LIMIT';
export const GET_CONTAINER_ORDER_FOR_EDIT = 'GET_CONTAINER_ORDER_FOR_EDIT';


// Features Type constants
export const GET_PRODUCT_CODES = 'GET_PRODUCT_CODES';
export const GET_PRODUCT_CODES_COUNT = 'GET_PRODUCT_CODES_COUNT';
export const GET_PRODUCT_CODES_SKIP = 'GET_PRODUCT_CODES_SKIP';
export const GET_PRODUCT_CODES_LIMIT = 'GET_PRODUCT_CODES_LIMIT';
export const GET_PRODUCT_CODE_FOR_EDIT = 'GET_PRODUCT_CODE_FOR_EDIT';


// Company LOCATION constants
export const GET_CUSTOM_COMPANY_PRODUCTS = 'GET_CUSTOM_COMPANY_PRODUCTS';
export const GET_CUSTOM_COMPANY_PRODUCTS_COUNT = 'GET_CUSTOM_COMPANY_PRODUCTS_COUNT';
export const GET_CUSTOM_COMPANY_PRODUCTS_SKIP = 'GET_CUSTOM_COMPANY_PRODUCTS_SKIP';
export const GET_CUSTOM_COMPANY_PRODUCTS_LIMIT = 'GET_CUSTOM_COMPANY_PRODUCTS_LIMIT';
export const GET_CUSTOM_COMPANY_PRODUCT_FOR_EDIT = 'GET_CUSTOM_COMPANY_PRODUCT_FOR_EDIT';


// Company Inventory constants
export const GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES = 'GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES';
export const GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES_COUNT = 'GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES_COUNT';
export const GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES_SKIP = 'GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES_SKIP';
export const GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES_LIMIT = 'GET_CUSTOM_COMPANY_PRODUCT_INVENTORIES_LIMIT';
export const GET_CUSTOM_COMPANY_PRODUCT_INVENTORY_FOR_EDIT = 'GET_CUSTOM_COMPANY_PRODUCT_INVENTORY_FOR_EDIT';
