import {
  GET_COMPANIES, GET_COMPANIES_COUNT, GET_COMPANY_FOR_EDIT,
  GET_COMPANIES_SKIP, GET_COMPANIES_LIMIT,
} from '../actions/types';

const initialValues = {
  name: '', suffix: '', owner: '', phone: '', email: '', address: '',
};
const defaultState = {
  docs: [], count: 0, skipped: 0, limit: 0, initialValues,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_COMPANIES:
      return { ...state, docs: payload || [] };
    case GET_COMPANIES_COUNT:
      return { ...state, count: payload || 0 };
    case GET_COMPANIES_SKIP:
      return { ...state, skipped: payload || 0 };
    case GET_COMPANIES_LIMIT:
      return { ...state, limit: payload || 0 };
    case GET_COMPANY_FOR_EDIT:
      return { ...state, initialValues: payload || initialValues };
    default:
      return state;
  }
};
