import axios from 'axios';
import { apiUrl, headerAuthTokenKey } from './config';
import { getToken } from './token';

const axiosInstance = axios.create({ baseURL: apiUrl });

const errHandling = (error) => Promise.reject(error);

const reqHandling = (config) => {
  const obj = { ...config };
  const token = getToken();
  if (token) obj.headers[headerAuthTokenKey] = token;

  return obj;
};

const resHandling = (response) => response;

axiosInstance.interceptors.request.use(reqHandling, errHandling);
axiosInstance.interceptors.response.use(resHandling, errHandling);

export default axiosInstance;
