import {
  GET_INVENTORIES, GET_INVENTORIES_COUNT, GET_INVENTORY_FOR_EDIT,
  GET_INVENTORIES_SKIP, GET_INVENTORIES_LIMIT, GET_STOCK_MOVING_PRODUCT,
  GET_STOCK_MOVING_LOCATION,
} from '../actions/types';

const initialValues = {
  _location: '', _product: '', sku: '', stock: '',
};
const defaultState = {
  docs: [],
  count: 0,
  skipped: 0,
  initialValues,
  movingProduct: {},
  movingLocation: {},
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_INVENTORIES:
      return { ...state, docs: payload || [] };
    case GET_INVENTORIES_COUNT:
      return { ...state, count: payload || 0 };
    case GET_INVENTORIES_SKIP:
      return { ...state, skipped: payload || 0 };
    case GET_INVENTORIES_LIMIT:
      return { ...state, limit: payload || 0 };
    case GET_INVENTORY_FOR_EDIT:
      return { ...state, initialValues: payload || initialValues };
    case GET_STOCK_MOVING_PRODUCT:
      return { ...state, movingProduct: payload || {} };
    case GET_STOCK_MOVING_LOCATION:
      return { ...state, movingLocation: payload || {} };
    default:
      return state;
  }
};
