import {
  GET_FEATURES, GET_FEATURES_COUNT, GET_FEATURE_FOR_EDIT, GET_FEATURES_SKIP, GET_FEATURES_LIMIT,
} from '../actions/types';

const initialValues = { key: '', description: '', _featureType: '' };
const defaultState = {
  docs: [], count: 0, skipped: 0, limit: 0, initialValues,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_FEATURES:
      return { ...state, docs: payload || [] };
    case GET_FEATURES_COUNT:
      return { ...state, count: payload || 0 };
    case GET_FEATURES_SKIP:
      return { ...state, skipped: payload || 0 };
    case GET_FEATURES_LIMIT:
      return { ...state, limit: payload || 0 };
    case GET_FEATURE_FOR_EDIT:
      return { ...state, initialValues: payload || initialValues };
    default:
      return state;
  }
};
