import Api from '../Api';
import history from '../history';
import {
  ARRAY, NUMBER, COUNT, SKIPPED, LIMIT,
} from '../constants';
import {
  GET_COMPANY_LOCATIONS, GET_COMPANY_LOCATIONS_COUNT,
  GET_COMPANY_LOCATION_FOR_EDIT, GET_COMPANY_LOCATIONS_SKIP, GET_COMPANY_LOCATIONS_LIMIT,
} from './types';
import {
  sendErrorMessage, validateResponseData, sendSuccessMessage,
  generateError, getCompanyIdFromState,
} from '../helper';

export const clientUrl = '/dashboard/companyLocation';
const apiUrl = '/companyLocation';
const addSuccessMsg = 'Location added successfuly!';
const updateSuccessMsg = 'Location updated successfully!';
const noDocErr = 'No location found!';

export const getAll = (values = {}) => async (dispatch, getState) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;
    params._company_eq = getCompanyIdFromState(getState);

    const { data } = await Api.get(url, { params });

    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_COMPANY_LOCATIONS, payload });

    const countPayload = validateResponseData({
      data, elementName: COUNT, elementType: NUMBER,
    });
    dispatch({ type: GET_COMPANY_LOCATIONS_COUNT, payload: countPayload });

    const skipPayload = validateResponseData({
      data, elementName: SKIPPED, elementType: NUMBER,
    });
    dispatch({ type: GET_COMPANY_LOCATIONS_SKIP, payload: skipPayload });

    const limitPayload = validateResponseData({
      data, elementName: LIMIT, elementType: NUMBER,
    });
    dispatch({ type: GET_COMPANY_LOCATIONS_LIMIT, payload: limitPayload });

    dispatch({ type: GET_COMPANY_LOCATION_FOR_EDIT });
  } catch (err) { sendErrorMessage(err); }
};

export const addNew = (values) => async () => {
  try {
    const { data } = await Api.post(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(addSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};

export const getOne = (values) => async (dispatch, getState) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;
    params._company_eq = getCompanyIdFromState(getState);
    if (!params || !params._id) throw generateError('No id found!');

    const { data } = await Api.get(url, { params });

    const docArr = validateResponseData({ data, elementType: ARRAY });
    if (docArr.length === 0) throw generateError(noDocErr);

    dispatch({ type: GET_COMPANY_LOCATION_FOR_EDIT, payload: docArr[0] });
  } catch (err) {
    sendErrorMessage(err);
    history.push(clientUrl);
  }
};

export const updateOne = (values) => async () => {
  try {
    const { data } = await Api.put(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(updateSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};
