import React, { Suspense, lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import store from './store';
import history from './history';
import { getToken } from './token';
import { authActions } from './actions';

const token = getToken();
if (token) store.dispatch(authActions.currentUser());

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const DefaultLayout = lazy(() => import('./containers/DefaultLayout'));
const Login = lazy(() => import('./pages/Login'));

const App = () => (
  <Provider store={store}>
    <ToastContainer position="top-right" autoClose={5000} style={{ zIndex: 1999 }} />
    <Router history={history}>
      <Suspense fallback={loading()}>
        <Switch>
          <Route path="/" name="Login" render={(props) => <Login {...props} />} exact />
          <Route path="/dashboard" name="Home" render={(props) => <DefaultLayout {...props} />} />
        </Switch>
      </Suspense>
    </Router>
  </Provider>
);

export default App;
