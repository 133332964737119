import { AUTH_USER } from '../actions/types';

const defaultState = {
  user: null,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case AUTH_USER:
      return { ...state, user: payload || null };
    default:
      return state;
  }
};
