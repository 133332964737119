import {
  GET_PRODUCTS, GET_PRODUCTS_COUNT, GET_PRODUCT_FOR_EDIT, GET_PRODUCTS_SKIP,
  GET_PRODUCTS_LIMIT, GET_NEW_SHIPMENT_PRODUCTS, GET_MY_COMPANY_PRODUCTS,
} from '../actions/types';

const initialValues = {
  name: '', sku: '', boxCapacity: '', price: '', pendingStock: 0,
};
const defaultState = {
  docs: [],
  count: 0,
  skipped: 0,
  limit: 0,
  initialValues,
  shipmentProducts: [],
  companyProducts: [],
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_PRODUCTS:
      return { ...state, docs: payload || [] };
    case GET_PRODUCTS_COUNT:
      return { ...state, count: payload || 0 };
    case GET_PRODUCTS_SKIP:
      return { ...state, skipped: payload || 0 };
    case GET_PRODUCTS_LIMIT:
      return { ...state, limit: payload || 0 };
    case GET_PRODUCT_FOR_EDIT:
      return { ...state, initialValues: payload || initialValues };
    case GET_NEW_SHIPMENT_PRODUCTS:
      return { ...state, shipmentProducts: payload || [] };
    case GET_MY_COMPANY_PRODUCTS:
      return { ...state, companyProducts: payload || [] };
    default:
      return state;
  }
};
