import Api from '../Api';
import history from '../history';
import {
  ARRAY, NUMBER, COUNT, SKIPPED, LIMIT,
} from '../constants';
import {
  GET_FEATURE_TYPE_FOR_EDIT, GET_FEATURE_TYPES, GET_FEATURE_TYPES_COUNT,
  GET_FEATURE_TYPES_SKIP, GET_FEATURE_TYPES_LIMIT,
} from './types';
import {
  sendErrorMessage, validateResponseData, sendSuccessMessage, generateError,
} from '../helper';

export const clientUrl = '/dashboard/feature/featureType';
const apiUrl = '/featureType';
const addSuccessMsg = 'Feature type added successfuly!';
const updateSuccessMsg = 'Feature type updated successfully!';
const noDocErr = 'No feature type found!';

export const getAll = (values = {}) => async (dispatch) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;

    const { data } = await Api.get(url, { params });

    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_FEATURE_TYPES, payload });

    const countPayload = validateResponseData({
      data, elementName: COUNT, elementType: NUMBER,
    });
    dispatch({ type: GET_FEATURE_TYPES_COUNT, payload: countPayload });

    const skipPayload = validateResponseData({
      data, elementName: SKIPPED, elementType: NUMBER,
    });
    dispatch({ type: GET_FEATURE_TYPES_SKIP, payload: skipPayload });

    const limitPayload = validateResponseData({
      data, elementName: LIMIT, elementType: NUMBER,
    });
    dispatch({ type: GET_FEATURE_TYPES_LIMIT, payload: limitPayload });

    dispatch({ type: GET_FEATURE_TYPE_FOR_EDIT });
  } catch (err) { sendErrorMessage(err); }
};

export const addNew = (values) => async () => {
  try {
    const { data } = await Api.post(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(addSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};

export const getOne = (values) => async (dispatch) => {
  try {
    const { paramsString, params } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;
    if (!params || !params._id) throw generateError('No id found!');

    const { data } = await Api.get(url, { params });

    const docArr = validateResponseData({ data, elementType: ARRAY });
    if (docArr.length === 0) throw generateError(noDocErr);

    dispatch({ type: GET_FEATURE_TYPE_FOR_EDIT, payload: docArr[0] });
  } catch (err) {
    sendErrorMessage(err);
    history.push(clientUrl);
  }
};

export const updateOne = (values) => async () => {
  try {
    const { data } = await Api.put(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(updateSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};
