import Api from '../Api';
import {
  ARRAY, NUMBER, COUNT, SKIPPED, LIMIT,
} from '../constants';
import {
  GET_PRODUCT_CODES, GET_PRODUCT_CODES_COUNT, GET_PRODUCT_CODES_SKIP, GET_PRODUCT_CODES_LIMIT,
} from './types';
import {
  sendErrorMessage, validateResponseData, sendSuccessMessage, generateError,
} from '../helper';

export const clientUrl = '/dashboard/productCode';
const apiUrl = '/productCode';
const addSuccessMsg = 'Product code added successfuly!';

export const getAll = (values = {}) => async (dispatch) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;

    const { data } = await Api.get(url, { params });

    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_PRODUCT_CODES, payload });

    const countPayload = validateResponseData({
      data, elementName: COUNT, elementType: NUMBER,
    });
    dispatch({ type: GET_PRODUCT_CODES_COUNT, payload: countPayload });

    const skipPayload = validateResponseData({
      data, elementName: SKIPPED, elementType: NUMBER,
    });
    dispatch({ type: GET_PRODUCT_CODES_SKIP, payload: skipPayload });

    const limitPayload = validateResponseData({
      data, elementName: LIMIT, elementType: NUMBER,
    });
    dispatch({ type: GET_PRODUCT_CODES_LIMIT, payload: limitPayload });
  } catch (err) { sendErrorMessage(err); }
};

export const addNew = (values, resetForm) => async (dispatch, getState) => {
  try {
    const { data } = await Api.post(apiUrl, values);

    const doc = validateResponseData({ data });
    if (!doc || !doc._id || !doc.code) throw generateError();

    const { productCode: { docs } } = getState();
    if (!docs || !Array.isArray(docs)) throw generateError();

    const payload = [...docs, doc];

    dispatch({ type: GET_PRODUCT_CODES, payload });
    sendSuccessMessage(addSuccessMsg);
    resetForm();
  } catch (err) { sendErrorMessage(err); }
};
