import {
  GET_CONTAINER_ORDERS, GET_CONTAINER_ORDERS_COUNT, GET_CONTAINER_ORDER_FOR_EDIT,
  GET_CONTAINER_ORDERS_SKIP, GET_CONTAINER_ORDERS_LIMIT,
} from '../actions/types';

const initialValues = { orderItems: [], containerNo: '' };
const defaultState = {
  docs: [], count: 0, skipped: 0, limit: 0, initialValues,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_CONTAINER_ORDERS:
      return { ...state, docs: payload || [] };
    case GET_CONTAINER_ORDERS_COUNT:
      return { ...state, count: payload || 0 };
    case GET_CONTAINER_ORDERS_SKIP:
      return { ...state, skipped: payload || 0 };
    case GET_CONTAINER_ORDERS_LIMIT:
      return { ...state, limit: payload || 0 };
    case GET_CONTAINER_ORDER_FOR_EDIT:
      return { ...state, initialValues: payload || initialValues };
    default:
      return state;
  }
};
