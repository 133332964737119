import Api from '../Api';
import {
  ARRAY, NUMBER, COUNT, SKIPPED, LIMIT,
} from '../constants';
import {
  GET_INVENTORIES, GET_INVENTORIES_COUNT, GET_INVENTORY_FOR_EDIT,
  GET_INVENTORIES_SKIP, GET_PRODUCT_FOR_EDIT, GET_INVENTORIES_LIMIT,
  GET_STOCK_MOVING_PRODUCT, GET_STOCK_MOVING_LOCATION,
} from './types';
import {
  sendErrorMessage, validateResponseData, sendSuccessMessage,
  generateError, getDataFromState,
} from '../helper';

export const clientUrl = '/dashboard/inventory';
const apiUrl = '/inventory';
const addSuccessMsg = 'Inventory added successfuly!';
const updateSuccessMsg = 'Inventory updated successfully!';
const moveStockMsg = 'Stock moved to location(s) successfully!';

export const getAll = (values = {}) => async (dispatch) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;

    const { data } = await Api.get(url, { params });

    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_INVENTORIES, payload });

    const countPayload = validateResponseData({
      data, elementName: COUNT, elementType: NUMBER,
    });
    dispatch({ type: GET_INVENTORIES_COUNT, payload: countPayload });

    const skipPayload = validateResponseData({
      data, elementName: SKIPPED, elementType: NUMBER,
    });
    dispatch({ type: GET_INVENTORIES_SKIP, payload: skipPayload });

    const limitPayload = validateResponseData({
      data, elementName: LIMIT, elementType: NUMBER,
    });
    dispatch({ type: GET_INVENTORIES_LIMIT, payload: limitPayload });

    dispatch({ type: GET_INVENTORY_FOR_EDIT });
  } catch (err) { sendErrorMessage(err); }
};

export const addNew = (values, setEmptyPids, cb) => async (dispatch, getState) => {
  try {
    const { data } = await Api.post(apiUrl, values);

    const docs = getDataFromState(getState, 'inventory', 'docs');
    if (!docs || !Array.isArray(docs)) throw generateError();

    const newDocs = validateResponseData({ data, elementType: ARRAY });
    const payload = [...docs, ...newDocs];
    const pids = newDocs.map((x) => x._id);
    setEmptyPids([...pids, ...values.existingPids]);

    dispatch({ type: GET_INVENTORIES, payload });
    cb();
    sendSuccessMessage(addSuccessMsg);
  } catch (err) { sendErrorMessage(err); }
};

export const updateStock = (values, cb) => async (dispatch, getState) => {
  try {
    const { data } = await Api.patch(`${apiUrl}/updateStock`, values);

    const error = generateError();

    const doc = validateResponseData({ data });
    if (!doc || typeof doc !== 'object' || !doc._id) throw error;

    const payload = getDataFromState(getState, 'inventory', 'docs');
    if (!payload || !Array.isArray(payload)) throw error;

    const index = payload.findIndex((x) => x._id === doc._id);
    if (index < 0) throw error;

    payload[index].stock = doc.stock;

    const product = getDataFromState(getState, 'product', 'initialValues');
    if (!product || typeof product !== 'object' || !product._id) throw error;

    const multiplyWith = values.type === 'add' ? -1 : 1;
    product.pendingStock += values.stock * multiplyWith;

    dispatch({ type: GET_PRODUCT_FOR_EDIT, payload: product });
    dispatch({ type: GET_INVENTORIES, payload });
    cb();
    sendSuccessMessage(updateSuccessMsg);
  } catch (err) { sendErrorMessage(err); }
};

export const moveStockFunc = (values, cb) => async (dispatch) => {
  try {
    const { data } = await Api.patch(`${apiUrl}/moveStock`, values);
    if (!data || !data.status) throw generateError('Unable to update stock!');

    dispatch({ type: GET_STOCK_MOVING_LOCATION });
    dispatch({ type: GET_STOCK_MOVING_PRODUCT });
    sendSuccessMessage(moveStockMsg);
    cb();
  } catch (err) { sendErrorMessage(err); }
};
