import { toast } from 'react-toastify';
import {
  ARRAY, STRING, NUMBER, BOOLEAN, OBJECT, USER_ROLES,
} from '../constants';

export const generateError = (msg = 'Something went wrong!') => {
  const err = new Error(msg);
  return err;
};

export const sendErrorMessage = (err) => {
  let message = typeof err === 'string' ? err : 'Something went wrong!';

  if (err && err.message && typeof err.message === 'string') {
    // eslint-disable-next-line prefer-destructuring
    message = err.message;
  }

  toast.error(message);
};

export const sendSuccessMessage = (msg = 'Success!') => toast.success(msg);

const validateDataTypes = (element, elementType) => {
  const err = generateError('Invalid data type found!');
  const type = typeof element;

  switch (elementType) {
    case STRING:
    case NUMBER:
    case BOOLEAN:
    case OBJECT:
      if (type !== elementType) throw err;
      break;
    case ARRAY:
      if (!Array.isArray(element)) throw err;
      break;
    default:
      break;
  }
};

export const validateResponseData = ({ data, elementName = 'data', elementType = OBJECT }) => {
  if (!data || !data.status || (!data[elementName] && data[elementName] !== 0)) {
    if (data && data.message) throw generateError(data.message);
    throw generateError('Unable to fetch data!');
  }

  validateDataTypes(data[elementName], elementType);

  return data[elementName];
};

export const findUserRoleText = (role) => {
  const selectedRole = USER_ROLES.find((x) => x._id === role);
  if (!selectedRole) return 'NA';
  return selectedRole.name;
};

export const getCompanyIdFromState = (state) => {
  const error = generateError('Company ID not found!');
  const { auth } = state();
  if (!auth || !auth.user || !auth.user._company) throw error;
  if (!auth.user._company._id) throw error;

  return auth.user._company._id;
};

export const getCompanyIdFromStateSafely = (state) => {
  const { auth } = state();
  if (!auth || !auth.user || !auth.user._company) return null;
  if (!auth.user._company._id) return null;

  return auth.user._company._id;
};

export const getDataFromState = (getState, mainVar, dataVar) => {
  const error = generateError();
  const state = getState();

  if (
    !state
    || typeof state !== 'object'
    || !state[mainVar]
    || typeof state[mainVar] !== 'object'
    || !state[mainVar][dataVar]
  ) throw error;

  return state[mainVar][dataVar];
};

export const escapeRegexCharacters = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const capitalizeFirstWord = (str) => str.split(' ')
  .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
  .join(' ');

export const capitalizeFirstWordOfUnderscore = (str) => capitalizeFirstWord(str)
  .split('-')
  .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
  .join('-');
