import { combineReducers } from 'redux';
import auth from './authReducer';
import companyInventory from './companyInventoryReducer';
import companyLocation from './companyLocationReducer';
import companyProduct from './companyProductReducer';
import company from './companyReducer';
import containerOrder from './containerOrderReducer';
import customCompanyProduct from './customCompanyProductReducer';
import customCompanyProductInventory from './customCompanyProductInventoryReducer';
import feature from './featureReducer';
import featureType from './featureTypeReducer';
import inventory from './inventoryReducer';
import location from './locationReducer';
import order from './orderReducer';
import product from './productReducer';
import productCode from './productCodeReducer';
import user from './userReducer';

export default combineReducers({
  auth,
  companyInventory,
  companyLocation,
  companyProduct,
  company,
  containerOrder,
  customCompanyProduct,
  customCompanyProductInventory,
  feature,
  featureType,
  inventory,
  location,
  order,
  product,
  productCode,
  user,
});
