export const ARRAY = 'array';
export const STRING = 'string';
export const BOOLEAN = 'boolean';
export const NUMBER = 'number';
export const OBJECT = 'object';

export const COUNT = 'count';
export const SKIPPED = 'skipped';
export const LIMIT = 'limit';

export const SUPER_ADMIN_ROLE = 'sa';
export const FACTORY_ADMIN_ROLE = 'factory_admin';
export const COMPANY_ADMIN_ROLE = 'company_admin';
export const MAIN_WORKER_ROLE = 'main_worker';
export const USER_ROLES = [
  { _id: MAIN_WORKER_ROLE, name: 'ISRA Worker' },
  { _id: COMPANY_ADMIN_ROLE, name: 'Company Admin' },
  { _id: FACTORY_ADMIN_ROLE, name: 'Factory Admin' },
];

export const STATUS_OPTIONS = [{ status: 'Active', value: true }, { status: 'Inactive', value: false }];

export const APPROVED = 'approved';
export const REJECTED = 'rejected';
export const PENDING = 'pending';
export const ORDER_STATUS = [APPROVED, REJECTED, PENDING];

export const DRAFTED = 'drafted';
export const CREATED = 'created';
export const SHIPPED = 'shipped';
export const COMPLETED = 'completed';
export const CONTAINER_ORDER_STATUS = [CREATED, SHIPPED, COMPLETED];

export const PAGE_FORMATS = [
  'a0', 'a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8', 'a9', 'a10',
  'b0', 'b1', 'b2', 'b3', 'b4', 'b5', 'b6', 'b7', 'b8', 'b9', 'b10',
  'c0', 'c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9', 'c10',
  'dl', 'letter', 'government-letter', 'legal', 'junior-legal', 'ledger', 'tabloid',
];
