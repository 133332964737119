import Api from '../Api';
import history from '../history';
import {
  ARRAY, NUMBER, COUNT, SKIPPED, LIMIT,
} from '../constants';
import {
  GET_CONTAINER_ORDER_FOR_EDIT, GET_CONTAINER_ORDERS, GET_CONTAINER_ORDERS_COUNT,
  GET_CONTAINER_ORDERS_SKIP, GET_CONTAINER_ORDERS_LIMIT,
} from './types';
import {
  sendErrorMessage, validateResponseData, sendSuccessMessage, generateError,
} from '../helper';

export const clientUrl = '/dashboard/containerOrder';
const apiUrl = '/containerOrder';
const addSuccessMsg = 'Order added successfuly!';
const updateSuccessMsg = 'Order updated successfully!';
const noDocErr = 'No container order found!';

export const getAll = (values = {}) => async (dispatch) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;

    const { data } = await Api.get(url, { params });

    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_CONTAINER_ORDERS, payload });

    const countPayload = validateResponseData({
      data, elementName: COUNT, elementType: NUMBER,
    });
    dispatch({ type: GET_CONTAINER_ORDERS_COUNT, payload: countPayload });

    const skipPayload = validateResponseData({
      data, elementName: SKIPPED, elementType: NUMBER,
    });
    dispatch({ type: GET_CONTAINER_ORDERS_SKIP, payload: skipPayload });

    const limitPayload = validateResponseData({
      data, elementName: LIMIT, elementType: NUMBER,
    });
    dispatch({ type: GET_CONTAINER_ORDERS_LIMIT, payload: limitPayload });

    dispatch({ type: GET_CONTAINER_ORDER_FOR_EDIT });
  } catch (err) { sendErrorMessage(err); }
};

export const addNew = (values) => async () => {
  try {
    const { data } = await Api.post(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(addSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};

export const getOne = (values) => async (dispatch) => {
  try {
    const { paramsString, params } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;
    if (!params || !params._id) throw generateError('No id found!');

    const { data } = await Api.get(url, { params });

    const docArr = validateResponseData({ data, elementType: ARRAY });
    if (docArr.length === 0) throw generateError(noDocErr);

    dispatch({ type: GET_CONTAINER_ORDER_FOR_EDIT, payload: docArr[0] });
  } catch (err) {
    sendErrorMessage(err);
    history.push(clientUrl);
  }
};

export const updateOne = (values) => async (dispatch, getState) => {
  try {
    const { data } = await Api.put(apiUrl, values);

    const updatedDoc = validateResponseData({ data });
    if (!updatedDoc || !updatedDoc._id) throw generateError();

    const { containerOrder } = getState();
    if (
      !containerOrder || !containerOrder.docs || !Array.isArray(containerOrder.docs)
    ) throw generateError();

    const payload = containerOrder.docs.map((doc) => {
      const obj = { ...doc };
      if (obj._id === updatedDoc._id) {
        obj.orderStatus = updatedDoc.orderStatus;
        obj.shippedDate = updatedDoc.shippedDate || null;
        obj.completedDate = updatedDoc.completedDate || null;
      }

      return obj;
    });

    dispatch({ type: GET_CONTAINER_ORDERS, payload });
    sendSuccessMessage(updateSuccessMsg);
  } catch (err) { sendErrorMessage(err); }
};

export const editOrder = (values) => async () => {
  try {
    const { data } = await Api.patch(`${apiUrl}/editOrder`, values);
    validateResponseData({ data });

    sendSuccessMessage(updateSuccessMsg);
    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};

export const approveOrder = (values) => async () => {
  try {
    const { data } = await Api.patch(`${apiUrl}/approveOrder`, values);
    validateResponseData({ data });

    sendSuccessMessage(updateSuccessMsg);
    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};
