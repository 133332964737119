import Api from '../Api';
import history from '../history';
import {
  ARRAY, NUMBER, COUNT, SKIPPED, LIMIT,
} from '../constants';
import {
  GET_LOCATIONS, GET_LOCATIONS_COUNT, GET_LOCATION_FOR_EDIT,
  GET_LOCATIONS_SKIP, GET_LOCATIONS_LIMIT, GET_STOCK_MOVING_LOCATION,
} from './types';
import {
  sendErrorMessage, validateResponseData, sendSuccessMessage, generateError,
} from '../helper';

export const clientUrl = '/dashboard/location';
const apiUrl = '/location';
const addSuccessMsg = 'Location added successfuly!';
const updateSuccessMsg = 'Location updated successfully!';
const noDocErr = 'No location found!';

export const getAll = (values = {}) => async (dispatch) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;

    const { data } = await Api.get(url, { params });

    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_LOCATIONS, payload });

    const countPayload = validateResponseData({
      data, elementName: COUNT, elementType: NUMBER,
    });
    dispatch({ type: GET_LOCATIONS_COUNT, payload: countPayload });

    const skipPayload = validateResponseData({
      data, elementName: SKIPPED, elementType: NUMBER,
    });
    dispatch({ type: GET_LOCATIONS_SKIP, payload: skipPayload });

    const limitPayload = validateResponseData({
      data, elementName: LIMIT, elementType: NUMBER,
    });
    dispatch({ type: GET_LOCATIONS_LIMIT, payload: limitPayload });

    dispatch({ type: GET_LOCATION_FOR_EDIT });
  } catch (err) { sendErrorMessage(err); }
};

export const addNew = (values) => async () => {
  try {
    const { data } = await Api.post(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(addSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};

export const getOne = (values) => async (dispatch) => {
  try {
    const { paramsString, params } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;
    if (!params || !params._id) throw generateError('No id found!');

    const { data } = await Api.get(url, { params });

    const docArr = validateResponseData({ data, elementType: ARRAY });
    if (docArr.length === 0) throw generateError(noDocErr);

    dispatch({ type: GET_LOCATION_FOR_EDIT, payload: docArr[0] });
  } catch (err) {
    sendErrorMessage(err);
    history.push(clientUrl);
  }
};

export const updateOne = (values) => async () => {
  try {
    const { data } = await Api.put(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(updateSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};

export const findLocationForStockMoving = (id) => async (dispatch) => {
  try {
    const url = `${apiUrl}/searchLocationForPendingStock/${id}`;

    const { data } = await Api.get(url);

    const payload = validateResponseData({ data });

    dispatch({ type: GET_STOCK_MOVING_LOCATION, payload });
  } catch (err) { sendErrorMessage(err); }
};

export const deleteOne = (id, cb) => async (dispatch, getState) => {
  try {
    const { data } = await Api.delete(`${apiUrl}/${id}`);
    if (!data.status) throw generateError(data.message);

    const { location } = getState();
    if (!location || !location.docs) throw generateError();

    const payload = location.docs.filter((x) => x._id !== id);
    dispatch({ type: GET_LOCATIONS, payload });

    sendSuccessMessage('Location deleted successfully!');
  } catch (err) { sendErrorMessage(err); }
  cb();
};
