import Api from '../Api';
import history from '../history';
import {
  ARRAY, NUMBER, COUNT, SKIPPED, LIMIT,
} from '../constants';
import {
  GET_COMPANY_PRODUCTS, GET_COMPANY_PRODUCTS_COUNT, GET_COMPANY_PRODUCT_FOR_EDIT,
  GET_COMPANY_PRODUCTS_SKIP, GET_COMPANY_PRODUCTS_LIMIT, GET_COMPANY_PRODUCTS_FOR_IMPORT,
} from './types';
import {
  sendErrorMessage, validateResponseData, sendSuccessMessage,
  generateError, getCompanyIdFromState, getDataFromState,
} from '../helper';

export const clientUrl = '/dashboard/companyProduct';
const apiUrl = '/companyProduct';
const addSuccessMsg = 'Product added successfuly!';
const updateSuccessMsg = 'Product updated successfully!';
const noDocErr = 'No product found!';

export const getAll = (values = {}) => async (dispatch, getState) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;
    params._company_eq = getCompanyIdFromState(getState);

    const { data } = await Api.get(url, { params });

    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_COMPANY_PRODUCTS, payload });

    const countPayload = validateResponseData({
      data, elementName: COUNT, elementType: NUMBER,
    });
    dispatch({ type: GET_COMPANY_PRODUCTS_COUNT, payload: countPayload });

    const skipPayload = validateResponseData({
      data, elementName: SKIPPED, elementType: NUMBER,
    });
    dispatch({ type: GET_COMPANY_PRODUCTS_SKIP, payload: skipPayload });

    const limitPayload = validateResponseData({
      data, elementName: LIMIT, elementType: NUMBER,
    });
    dispatch({ type: GET_COMPANY_PRODUCTS_LIMIT, payload: limitPayload });

    dispatch({ type: GET_COMPANY_PRODUCT_FOR_EDIT });
  } catch (err) { sendErrorMessage(err); }
};

export const addNew = (values) => async () => {
  try {
    const { data } = await Api.post(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(addSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};

export const getOne = (values) => async (dispatch, getState) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;
    params._company_eq = getCompanyIdFromState(getState);
    if (!params || !params._id) throw generateError('No id found!');

    const { data } = await Api.get(url, { params });

    const docArr = validateResponseData({ data, elementType: ARRAY });
    if (docArr.length === 0) throw generateError(noDocErr);

    dispatch({ type: GET_COMPANY_PRODUCT_FOR_EDIT, payload: docArr[0] });
  } catch (err) {
    sendErrorMessage(err);
    history.push(clientUrl);
  }
};

export const updateOne = (values) => async (dispatch, getState) => {
  try {
    const { data } = await Api.put(apiUrl, values);
    const doc = validateResponseData({ data });

    const stateDocs = getDataFromState(getState, 'companyProduct', 'docs');
    const payload = stateDocs.filter((x) => x._id !== doc._id);

    dispatch({ type: GET_COMPANY_PRODUCTS, payload });
    sendSuccessMessage(updateSuccessMsg);
  } catch (err) { sendErrorMessage(err); }
};

export const getProductsForImport = () => async (dispatch) => {
  try {
    const { data } = await Api.get(`${apiUrl}/importProducts`);

    const payload = validateResponseData({ data, elementType: ARRAY });

    dispatch({ type: GET_COMPANY_PRODUCTS_FOR_IMPORT, payload });
  } catch (err) { sendErrorMessage(err); }
};

export const importNewProducts = (products) => async (dispatch) => {
  try {
    if (products.length === 0) throw generateError('No product selected!');

    const { data } = await Api.patch(`${apiUrl}/importProducts`, { products });

    validateResponseData({ data });

    history.push(clientUrl);

    sendSuccessMessage('Products imported successfully!');
    dispatch({ type: GET_COMPANY_PRODUCTS_FOR_IMPORT });
  } catch (err) { sendErrorMessage(err); }
};
