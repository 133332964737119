import {
  GET_CUSTOM_COMPANY_PRODUCTS, GET_CUSTOM_COMPANY_PRODUCTS_COUNT,
  GET_CUSTOM_COMPANY_PRODUCT_FOR_EDIT, GET_CUSTOM_COMPANY_PRODUCTS_SKIP,
  GET_CUSTOM_COMPANY_PRODUCTS_LIMIT,
} from '../actions/types';

const initialValues = {
  name: '', sku: '', boxCapacity: '', price: '', pendingStock: '',
};
const defaultState = {
  docs: [], count: 0, skipped: 0, limit: 0, initialValues,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_CUSTOM_COMPANY_PRODUCTS:
      return { ...state, docs: payload || [] };
    case GET_CUSTOM_COMPANY_PRODUCTS_COUNT:
      return { ...state, count: payload || 0 };
    case GET_CUSTOM_COMPANY_PRODUCTS_SKIP:
      return { ...state, skipped: payload || 0 };
    case GET_CUSTOM_COMPANY_PRODUCTS_LIMIT:
      return { ...state, limit: payload || 0 };
    case GET_CUSTOM_COMPANY_PRODUCT_FOR_EDIT:
      return { ...state, initialValues: payload || initialValues };
    default:
      return state;
  }
};
