import {
  GET_PRODUCT_CODES, GET_PRODUCT_CODES_COUNT, GET_PRODUCT_CODE_FOR_EDIT,
  GET_PRODUCT_CODES_SKIP, GET_PRODUCT_CODES_LIMIT,
} from '../actions/types';

const initialValues = { _product: '', code: '' };
const defaultState = {
  docs: [], count: 0, skipped: 0, limit: 0, initialValues,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_PRODUCT_CODES:
      return { ...state, docs: payload || [] };
    case GET_PRODUCT_CODES_COUNT:
      return { ...state, count: payload || 0 };
    case GET_PRODUCT_CODES_SKIP:
      return { ...state, skipped: payload || 0 };
    case GET_PRODUCT_CODES_LIMIT:
      return { ...state, limit: payload || 0 };
    case GET_PRODUCT_CODE_FOR_EDIT:
      return { ...state, initialValues: payload || initialValues };
    default:
      return state;
  }
};
