import {
  GET_LOCATIONS, GET_LOCATIONS_COUNT, GET_LOCATION_FOR_EDIT,
  GET_LOCATIONS_SKIP, GET_LOCATIONS_LIMIT,
} from '../actions/types';

const initialValues = { name: '' };
const defaultState = {
  docs: [], count: 0, skipped: 0, limit: 0, initialValues,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_LOCATIONS:
      return { ...state, docs: payload || [] };
    case GET_LOCATIONS_COUNT:
      return { ...state, count: payload || 0 };
    case GET_LOCATIONS_SKIP:
      return { ...state, skipped: payload || 0 };
    case GET_LOCATIONS_LIMIT:
      return { ...state, limit: payload || 0 };
    case GET_LOCATION_FOR_EDIT:
      return { ...state, initialValues: payload || initialValues };
    default:
      return state;
  }
};
