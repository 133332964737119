import Api from '../Api';
import history from '../history';
import {
  ARRAY, NUMBER, COUNT, SKIPPED, LIMIT,
} from '../constants';
import {
  GET_USERS, GET_USERS_COUNT, GET_USER_FOR_EDIT, GET_USERS_SKIP, GET_USERS_LIMIT,
} from './types';
import {
  sendErrorMessage, validateResponseData, sendSuccessMessage, generateError, getDataFromState,
} from '../helper';

export const clientUrl = '/dashboard/user';
const apiUrl = '/user';
const addSuccessMsg = 'User added successfuly!';
const updateSuccessMsg = 'User updated successfully!';
const noDocErr = 'No user found!';

export const getAll = (values = {}) => async (dispatch) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;

    const { data } = await Api.get(url, { params });

    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_USERS, payload });

    const countPayload = validateResponseData({
      data, elementName: COUNT, elementType: NUMBER,
    });
    dispatch({ type: GET_USERS_COUNT, payload: countPayload });

    const skipPayload = validateResponseData({
      data, elementName: SKIPPED, elementType: NUMBER,
    });
    dispatch({ type: GET_USERS_SKIP, payload: skipPayload });

    const limitPayload = validateResponseData({
      data, elementName: LIMIT, elementType: NUMBER,
    });
    dispatch({ type: GET_USERS_LIMIT, payload: limitPayload });

    dispatch({ type: GET_USER_FOR_EDIT });
  } catch (err) { sendErrorMessage(err); }
};

export const addNew = (values) => async () => {
  try {
    const { data } = await Api.post(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(addSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};

export const getOne = (values) => async (dispatch) => {
  try {
    const { paramsString, params } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;
    if (!params || !params._id) throw generateError('No id found!');

    const { data } = await Api.get(url, { params });

    const docArr = validateResponseData({ data, elementType: ARRAY });
    if (docArr.length === 0) throw generateError(noDocErr);

    dispatch({ type: GET_USER_FOR_EDIT, payload: docArr[0] });
  } catch (err) {
    sendErrorMessage(err);
    history.push(clientUrl);
  }
};

export const updateOne = (values) => async (dispatch, getState) => {
  try {
    const { data } = await Api.put(apiUrl, values);
    const doc = validateResponseData({ data });

    const stateDocs = getDataFromState(getState, 'user', 'docs');
    const payload = [...stateDocs];

    const index = payload.findIndex((x) => x._id === doc._id);
    if (index < 0) throw generateError();

    payload[index].status = doc.status;

    dispatch({ type: GET_USERS, payload });
    sendSuccessMessage(updateSuccessMsg);
  } catch (err) { sendErrorMessage(err); }
};

export const resetPasswordByAdmin = ({ _id, password }, cb) => async () => {
  try {
    if (!_id || !password) throw generateError();

    const { data } = await Api.patch(`${apiUrl}/resetPasswordByAdmin/${_id}`, { password });
    if (data || data.status) {
      if (cb) cb();
      return sendSuccessMessage('Password reset sucessfully!');
    }

    if (data && data.message) throw generateError(data.message);
    throw generateError('Unable to reset password!');
  } catch (err) { return sendErrorMessage(err); }
};
