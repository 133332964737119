import {
  GET_USERS, GET_USERS_COUNT, GET_USER_FOR_EDIT, GET_USERS_SKIP, GET_USERS_LIMIT,
} from '../actions/types';

const initialValues = {
  _company: '', username: '', role: '', password: '', rpassword: '',
};
const defaultState = {
  docs: [], count: 0, skipped: 0, limit: 0, initialValues,
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_USERS:
      return { ...state, docs: payload || [] };
    case GET_USERS_COUNT:
      return { ...state, count: payload || 0 };
    case GET_USERS_SKIP:
      return { ...state, skipped: payload || 0 };
    case GET_USERS_LIMIT:
      return { ...state, limit: payload || 0 };
    case GET_USER_FOR_EDIT:
      return {
        ...state, initialValues: payload ? { ...initialValues, ...payload } : initialValues,
      };
    default:
      return state;
  }
};
