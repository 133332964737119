import Api from '../Api';
import history from '../history';
import { AUTH_USER } from './types';
import {
  sendErrorMessage, validateResponseData, sendSuccessMessage, generateError,
} from '../helper';
import { setToken, removeToken } from '../token';
import { headerAuthTokenKey } from '../config';

const apiUrl = '/auth';

export const currentUser = () => async (dispatch) => {
  try {
    const { data } = await Api.get(`${apiUrl}/login`);
    const payload = validateResponseData({ data });

    return dispatch({ type: AUTH_USER, payload });
  } catch (err) {
    removeToken();
    history.push('/');
    return sendErrorMessage(err);
  }
};

export const loginUser = (values) => async (dispatch) => {
  try {
    const { data, headers } = await Api.post(`${apiUrl}/login`, values);
    const payload = validateResponseData({ data });
    const token = headers[headerAuthTokenKey];
    if (!payload || !token) throw generateError('Unable to login!');

    sendSuccessMessage('Login successfully!');
    setToken(token);
    dispatch({ type: AUTH_USER, payload });
    return history.push('/dashboard');
  } catch (err) { return sendErrorMessage(err); }
};

export const logoutUser = () => async (dispatch) => {
  history.push('/');
  removeToken();
  dispatch({ type: AUTH_USER });
  sendSuccessMessage('Logout successfully!');
  await Api.get(`${apiUrl}/logout`);
};
