import Api from '../Api';
import history from '../history';
import {
  ARRAY, NUMBER, COUNT, SKIPPED, LIMIT,
} from '../constants';
import {
  GET_PRODUCTS, GET_PRODUCTS_COUNT, GET_PRODUCT_FOR_EDIT, GET_PRODUCTS_SKIP,
  GET_PRODUCTS_LIMIT, GET_NEW_SHIPMENT_PRODUCTS, GET_STOCK_MOVING_PRODUCT,
  GET_MY_COMPANY_PRODUCTS,
} from './types';
import {
  sendErrorMessage, validateResponseData, sendSuccessMessage, generateError, getDataFromState,
} from '../helper';

export const clientUrl = '/dashboard/product';
const apiUrl = '/product';
const addSuccessMsg = 'Product added successfuly!';
const updateSuccessMsg = 'Product updated successfully!';
const noDocErr = 'No product found!';
const newShipmentMsg = 'New shipment added successfully!';

export const getAll = (values = {}) => async (dispatch) => {
  try {
    const { paramsString, params = {} } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;

    const { data } = await Api.get(url, { params });

    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_PRODUCTS, payload });

    const countPayload = validateResponseData({
      data, elementName: COUNT, elementType: NUMBER,
    });
    dispatch({ type: GET_PRODUCTS_COUNT, payload: countPayload });

    const skipPayload = validateResponseData({
      data, elementName: SKIPPED, elementType: NUMBER,
    });
    dispatch({ type: GET_PRODUCTS_SKIP, payload: skipPayload });

    const limitPayload = validateResponseData({
      data, elementName: LIMIT, elementType: NUMBER,
    });
    dispatch({ type: GET_PRODUCTS_LIMIT, payload: limitPayload });

    dispatch({ type: GET_PRODUCT_FOR_EDIT });
  } catch (err) { sendErrorMessage(err); }
};

export const addNew = (values) => async () => {
  try {
    const { data } = await Api.post(apiUrl, values);

    validateResponseData({ data });
    sendSuccessMessage(addSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};

export const getOne = (values) => async (dispatch) => {
  try {
    const { paramsString, params } = values;
    const url = paramsString ? apiUrl + paramsString : apiUrl;
    if (!params || !params._id) throw generateError('No id found!');

    const { data } = await Api.get(url, { params });

    const docArr = validateResponseData({ data, elementType: ARRAY });
    if (docArr.length === 0) throw generateError(noDocErr);

    dispatch({ type: GET_PRODUCT_FOR_EDIT, payload: docArr[0] });
  } catch (err) {
    sendErrorMessage(err);
    history.push(clientUrl);
  }
};

export const updateOne = (values) => async () => {
  try {
    const obj = { ...values };
    delete obj.sku;
    const { data } = await Api.put(apiUrl, obj);

    validateResponseData({ data });
    sendSuccessMessage(updateSuccessMsg);

    history.push(clientUrl);
  } catch (err) { sendErrorMessage(err); }
};

export const updateStock = (values, cb) => async (dispatch, getState) => {
  try {
    const { data } = await Api.patch(`${apiUrl}/updateStock`, values);

    const error = generateError();

    const product = validateResponseData({ data });
    if (!product || typeof product !== 'object' || !product._id) throw error;

    const payload = getDataFromState(getState, 'product', 'docs');
    if (!payload || !Array.isArray(payload)) throw error;

    const index = payload.findIndex((x) => x._id === product._id);
    if (index < 0) throw error;

    payload[index].pendingStock = product.pendingStock;
    payload[index].totalStock = product.totalStock;

    dispatch({ type: GET_PRODUCTS, payload });
    cb();
    sendSuccessMessage(updateSuccessMsg);
  } catch (err) { sendErrorMessage(err); }
};

export const searchProduct = (value) => async (dispatch, getState) => {
  try {
    const url = `${apiUrl}/_id/name/sku/boxCapacity`;
    const params = { status_eq: true, sku_eq: value };

    const { data } = await Api.get(url, { params });

    const docArr = validateResponseData({ data, elementType: ARRAY });
    if (docArr.length === 0) throw generateError('Invalid SKU!');

    const payload = getDataFromState(getState, 'product', 'shipmentProducts');
    if (!payload || !Array.isArray(payload)) throw generateError();

    const productExist = payload.find((x) => x._id === docArr[0]._id);
    if (productExist) throw generateError('Product already exists in list!');

    dispatch({ type: GET_NEW_SHIPMENT_PRODUCTS, payload: [...payload, docArr[0]] });
  } catch (err) { sendErrorMessage(err); }
};

export const newShipment = (values, resetForm) => async (dispatch) => {
  try {
    const { data } = await Api.patch(`${apiUrl}/newShipment`, values);

    if (!data || !data.status) throw generateError('Unable to add shipment!');

    sendSuccessMessage(newShipmentMsg);
    dispatch({ type: GET_NEW_SHIPMENT_PRODUCTS });
    resetForm();
  } catch (err) { sendErrorMessage(err); }
};

export const findProductForStockMoving = (id) => async (dispatch) => {
  try {
    const url = `${apiUrl}/searchProductForPendingStock/${id}`;

    const { data } = await Api.get(url);

    const payload = validateResponseData({ data });
    if (!payload.pendingStock) throw generateError('Product does not have any pending stock!');

    dispatch({ type: GET_STOCK_MOVING_PRODUCT, payload });
  } catch (err) { sendErrorMessage(err); }
};

export const getCompanyProducts = () => async (dispatch) => {
  try {
    const { data } = await Api.get(`${apiUrl}/myCompanyProducts`);

    const payload = validateResponseData({ data, elementType: ARRAY });
    dispatch({ type: GET_MY_COMPANY_PRODUCTS, payload });
  } catch (err) { sendErrorMessage(err); }
};

export const submitRequestToGenerateBarcodes = () => async () => {
  try {
    const { data } = await Api.get(`${apiUrl}/generatePendingBarCodes`);
    validateResponseData({ data });
    sendSuccessMessage('Request submitted successfully!');
  } catch (err) { sendErrorMessage(err); }
};

export const deleteOne = (id, cb) => async (dispatch, getState) => {
  try {
    const { data } = await Api.delete(`${apiUrl}/${id}`);
    if (!data.status) throw generateError(data.message);

    const { product } = getState();
    if (!product || !product.docs) throw generateError();

    const payload = product.docs.filter((x) => x._id !== id);
    dispatch({ type: GET_PRODUCTS, payload });

    sendSuccessMessage('Product deleted successfully!');
  } catch (err) { sendErrorMessage(err); }
  cb();
};
